import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const BeatFeedBack = () => import('../views/beatFeedBack.vue')
const kingMeal = () => import('../views/kingMeal.vue')
const usdtCourse = () => import('../views/usdtCourse.vue')
const recommendMoneyNet = () => import('../views/recommendMoneyNet.vue')
const usdtDealCourse = () => import('../views/usdtDealCourse.vue')
const bitcoinDeal = () => import('../views/bitcoinDeal.vue')
const binanceDeal = () => import('../views/binanceDeal.vue')
const usdtVideoCourse = () => import('../views/usdtVideoCourse.vue')
const bitcoinVideoCourse = () => import('../views/bitcoinVideoCourse.vue')
const mobiDeal = () => import('../views/mobiDeal.vue')
const ChessSave = () => import('../views/ChessSave.vue')
const friendRecommend = () => import('../views/friendRecommend.vue')
const RealSuperGold = () => import('../views/RealSuperGold.vue')
const DptBonus = () => import('../views/DptBonus.vue')
const DptGift = () => import('../views/DptGift.vue')
const chessHand = () => import('../views/ChessHand.vue')
const recruit = () => import('../views/Recruit.vue')
const usdtRefund = () => import('../views/usdtRefund.vue')
const usdtRefund2 = () => import('../views/usdtRefund2.vue')

const login = () => import('../views/login.vue')
const newsyeardeposit = () => import('../views/newsyeardeposit.vue')
const newsyearredpacket = () => import('../views/newsyearredpacket.vue')
const Sportsgames = () => import('../views/Sportsgames.vue')
const turntable = () => import('../views/turntable.vue')
const newpeople = () => import('../views/newpeople.vue')
const quizactivities = () => import('../views/quizactivities.vue')
const Withdrawalshelp = () => import('../views/Withdrawalshelp.vue')
const Beautylive = () => import('../views/Beautylive.vue')
const Esportswelfare = () => import('../views/Esportswelfare.vue')
const Esportssend = () => import('../views/Esportssend.vue')
const Chesssend = () => import('../views/Chesssend.vue')
const KingMealInfol = () => import('../views/KingMealInfol.vue')
const privateparties = () => import('../views/privateparties.vue')
const electronic = () => import('../views/electronic.vue')
const LabourDay = () => import('../views/LabourDay.vue')
const Sportsrenew = () => import('../views/Sportsrenew.vue')

const league = () => import('../views/league.vue')
const Chessrenew = () => import('../views/Chessrenew.vue')
const electronicrenew = () => import('../views/electronicrenew.vue')
const Realrenew = () => import('../views/Realrenew.vue')
const oldmember = () => import('../views/oldmember.vue')
const everyweekvip = () => import('../views/everyweekvip.vue')
const Compensate = () => import('../views/Compensate.vue')
const Dailydeposit = () => import('../views/Dailydeposit.vue')
const DailydepositDiscounts = () => import('../views/DailydepositDiscounts.vue')
const Nationalagency = () => import('../views/Nationalagency.vue')
const Monthlyflowsport = () => import('../views/Monthlyflowsport.vue')
const Monthlyflowchess = () => import('../views/Monthlyflowchess.vue')
const Monthlyflowelect = () => import('../views/Monthlyflowelect.vue')
const Monthlyflowvideo = () => import('../views/Monthlyflowvideo.vue')
const Weekendflow = () => import('../views/Weekendflow.vue')

const NewYear = () => import('../views/NewYear.vue')
const DptBonusPlus = () => import('../views/DptBonusPlus.vue')
const ChessPlus = () => import('../views/ChessPlus.vue')
const RealrePlus = () => import('../views/RealrePlus.vue')






const routes = [
  {
    path: '',
    redirect: '/',
  },

  {
    // 返水巨惠
    path: '/BeatFeedBack',
    component: BeatFeedBack,
  },
  {
    //棋牌送彩
    path: '/ChessHand',
    component: chessHand,
  },
  {
    //棋牌负利
    path: '/ChessSave',
    component: ChessSave,
  },
  {
    //体育首存
    path: '/DptBonus',
    component: DptBonus,
  },
  {
    //首存送礼
    path: '/DptGift',
    component: DptGift,
  },
  {
    //邀请返利
    path: '/friendRecommend',
    component: friendRecommend,
  },
  {
    // 体育保单
    path: '/kingMeal',
    component: kingMeal,
  },
  {
    //真人连胜8场
    path: '/RealSuperGold',
    component: RealSuperGold,
  },
  {
    //真人存送
    path: '/Recruit',
    component: recruit,
  },
  {
    //体育连胜
    path: '/Sportsgames',
    component: Sportsgames,
  },
  {
    //幸运转盘
    path: '/turntable',
    component: turntable,
  },
  {
    //新人7日
    path: '/newpeople',
    component: newpeople,
  },
  {
    //竞猜活动
    path: '/quizactivities',
    component: quizactivities,
  },
  {
    //虚拟币提款帮助
    path: '/Withdrawalshelp',
    component: Withdrawalshelp,
  },
  {
    //主播直播
    path: '/Beautylive',
    component: Beautylive,
  },
  {
    //电竞福利
    path: '/Esportswelfare',
    component: Esportswelfare,
  },
  {
    //电竞存送
    path: '/Esportssend',
    component: Esportssend,
  },
  {
    //棋牌存送
    path: '/Chesssend',
    component: Chesssend,
  },
  {
    //五大联赛
    path: '/league',
    component: league,
  },

  {
    //虚拟币交易教程列表
    path: '/usdtDealCourse',
    component: usdtDealCourse,
  },
  {
    //了解USDT
    path: '/usdtCourse',
    component: usdtCourse,
  },

  {
    //推荐网币
    path: '/recommendMoneyNet',
    component: recommendMoneyNet,
  },
  {
    //比特拍交易
    path: '/bitcoinDeal',
    component: bitcoinDeal,
  },
  {
    //币安交易
    path: '/binanceDeal',
    component: binanceDeal,
  },
  {
    //泰达币USDT视频教程
    path: '/usdtVideoCourse',
    component: usdtVideoCourse,
  },
  {
    //比他派钱包视频教程
    path: '/bitcoinVideoCourse',
    component: bitcoinVideoCourse,
  },
  {
    //MOBI交易教程
    path: '/mobiDeal',
    component: mobiDeal,
  },

  {
    //虚拟币十返利
    path: '/UsdtRefund',
    component: usdtRefund,
  },
  {
    //虚拟币十返利
    path: '/UsdtRefund2',
    component: usdtRefund2,
  },
  {
    //登录页
    path: '/login',
    component: login,
    meta: { title: '邀请好友' },
  },
  {
    //新春首存
    path: '/newsyeardeposit',
    component: newsyeardeposit,
  },
  {
    //新春红包
    path: '/newsyearredpacket',
    component: newsyearredpacket,
  },
  {
    //篮球保单
    path: '/KingMealInfol',
    component: KingMealInfol,
  },
  {
    //每周私享会
    path: '/privateparties',
    component: privateparties,
  },
  {
    //电竞保单
    path: '/electronic',
    component: electronic,
  },
  {
    //五一
    path: '/LabourDay',
    component: LabourDay,
  },
  {
    //每周体育续存
    path: '/Sportsrenew',
    component: Sportsrenew,
  },
  {
    //每周真人续存
    path: '/Realrenew',
    component: Realrenew,
  },
  {
    //每周电竞续存
    path: '/electronicrenew',
    component: electronicrenew,
  },
  {
    //每周棋牌续存
    path: '/Chessrenew',
    component: Chessrenew,
  },
  {
    //老会员
    path: '/oldmember',
    component: oldmember,
  },
  {
    //VIP会员每周红包
    path: '/everyweekvip',
    component: everyweekvip,
  },
  {
    //至尊会员赔付金
    path: '/Compensate',
    component: Compensate,
  },
  {
    //每日存款加赠
    path: '/Dailydeposit',
    component: Dailydeposit,
  },
  {
    //全民代理
    path: '/Nationalagency',
    component: Nationalagency,
  },
  {
    //每日存款优惠
    path: '/DailydepositDiscounts',
    component: DailydepositDiscounts,
  },
  {
    //每月体育流水福利
    path: '/Monthlyflowsport',
    component: Monthlyflowsport,
  },
  {
    //每月电竞流水福利
    path: '/Monthlyflowelect',
    component: Monthlyflowelect,
  },
  {
    //每月棋牌流水福利
    path: '/Monthlyflowchess',
    component: Monthlyflowchess,
  },
  {
    //每月真人流水福利
    path: '/Monthlyflowvideo',
    component: Monthlyflowvideo,
  },
  {
    //周末存款加赠
    path: '/Weekendflow',
    component: Weekendflow,
  },
  {
    //节日首存
    path: '/NewYear',
    component: NewYear,
  },
  {
    //体育首存Plus
    path: '/DptBonusPlus',
    component: DptBonusPlus,
  },
  {
    //棋牌首存Plus
    path: '/ChessPlus',
    component: ChessPlus,
  },
  {
    //真人首存Plus
    path: '/RealrePlus',
    component: RealrePlus,
  },
  
]

const router = new VueRouter({
  routes,
})

export default router
