import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Toast } from 'vant'
import antd from 'ant-design-vue'
import VueCookies from 'vue-cookies'
import { Swipe, SwipeItem } from 'vant'
/* import './assets/css/resetvant.less' */
import scroll from 'vue-seamless-scroll'
import VueAxios from 'vue-axios'
import axios from 'axios'
import { NavBar, Popup } from 'vant'
import { RadioGroup, Radio } from 'vant';
import { Field } from 'vant';
import { Dialog } from 'vant'
import { CountDown } from 'vant';
import { Picker } from 'vant';

Vue.use(Picker);
Vue.use(CountDown);
Vue.use(antd)

Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Toast)
Vue.use(NavBar)
Vue.use(Popup)
Vue.use(VueCookies)
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Dialog);
Vue.use(scroll)

Vue.use(VueAxios, axios)
Vue.use(Field);
import Video from 'video.js'
import 'video.js/dist/video-js.css'

Vue.prototype.$video = Video
Vue.prototype.imgUrl = 'https://begood.gz.bcebos.com/h5/webviewImg/'
Vue.prototype.videoUrl = 'https://begood.gz.bcebos.com/h5/video/'
Vue.config.productionTip = false

Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})


// 链接带a_code重置cookie AgentCode，并删除链接上a_code参数
// if (window.location.hash.indexOf('a_code') != -1) {
//   $cookies.set('AgentCode', window.location.hash.split('=')[1])
//   history.pushState(
//     '',
//     ' 贝宝体育--您的首选',
//     window.location.hash.split('?')[0]
//   )
// }
new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
